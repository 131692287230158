//check active header
$(function () {
    var pageurl = window.location.href;
    var lastChar = pageurl[pageurl.length - 1];
    if(lastChar === '/'){
        pageurl = pageurl.slice('', -1);
    }

    $(".check-active li a").each(function(){
        var href = $(this).attr('href');
        if (href === pageurl)
        {
            $(this).addClass("active");
        }
    });
});


$(document).ready(function() {
    // start
    var $star_rating = $('.star-rating .fa');

    var SetRatingStar = function() {
        return $star_rating.each(function() {
            if (parseInt($star_rating.siblings('input.rating-value').val()) >= parseInt($(this).data('rating'))) {
                return $(this).addClass('star-active');
            } else {
                return $(this).removeClass('star-active')
            }
        });
    };

    $star_rating.on('click', function() {
        $star_rating.siblings('input.rating-value').val($(this).data('rating'));
        return SetRatingStar();
    });

    SetRatingStar();
});


$(function () {
    $(".nav-header a").on("click", function () {
        $(this).addClass('active-link');
        $('a').removeClass('active')
    });
    //check
    $("input[name=check_another_address]").on("click", function (e) {
        $(this).toggleClass();
        if (e) {
            $(".disabled-hide").prop('disabled', !$(".disabled-hide").prop('disabled'));
        } else {
            $(".disabled-hide").removeAttr('disabled', !$(".disabled-hide").removeAttr('disabled'));
        }

    });

    $(".checkded").on("click", function (e) {
        $(this).toggleClass();
        if (e) {
            $(".disabled-hide").prop('disabled', !$(".disabled-hide").prop('disabled'));
        } else {
            $(".disabled-hide").removeAttr('disabled', !$(".disabled-hide").removeAttr('disabled'));
        }

    });
  // menu humbarger
  $("#btn-hamburger").on("click", function () {
    $(".menu-aside-page").toggleClass("show");
    $(".btn-hamburger").toggleClass("show");
    $("body").toggleClass("overflow-hidden");
  });

  $(".backdrop , .menu-aside-close").on("click", function () {
    $(".menu-aside-page").removeClass("show");
    $("body").removeClass("overflow-hidden");
    $(".btn-hamburger").removeClass("show");
  });

  // $("img.lazyload").lazyload();





    $(".slick-dots li").on('click', function (e) {
        e.preventDefault();
        var slickElement = document.querySelector('.artists .slick-list');
        if (slickElement) {
            slickElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    });

  // fix price page detail
  var doc = document;
  var _footer,
    _fixedBlock = null;

  _footer = doc.querySelector("#footer");
  _fixedBlock = doc.querySelector(".order-details.fixed-block");

  var _height_window = null;
  var scrolled = null;

  if (_footer && _fixedBlock) {
    _height_window = window.innerHeight;
    if (_footer.getBoundingClientRect().top <= _height_window) {
      if (_fixedBlock.classList.contains("fixed-block"))
        _fixedBlock.classList.remove("fixed-block");
    } else {
      if (!_fixedBlock.classList.contains("fixed-block"))
        _fixedBlock.classList.add("fixed-block");
    }

    window.addEventListener("scroll", function (event) {
      _height_window = window.innerHeight;
      if (_footer.getBoundingClientRect().top <= _height_window) {
        if (_fixedBlock.classList.contains("fixed-block"))
          _fixedBlock.classList.remove("fixed-block");
      } else {
        if (!_fixedBlock.classList.contains("fixed-block"))
          _fixedBlock.classList.add("fixed-block");
      }
    });
  }

  // change image
  var images = doc.querySelector(".box-caricatures .image-main");
  var images_img = doc.querySelector(".box-caricatures .image-main img");
  var list_images = doc.querySelector(".box-caricatures .list-of-images");

  if (!list_images || !images) return;

  var _buff_src_click = null;
  var checker = true;

  list_images.addEventListener("click", function (event) {
    event.preventDefault();

    //var _this_e = event;

    if (!checker) return;

    _buff_src_click = null;
    if (event.target.nodeName == "LI")
      _buff_src_click = event.target.querySelector("img").src;
    else if (event.target.nodeName == "IMG") _buff_src_click = event.target.src;
    else return;
    checker = false;
    images_img.src = _buff_src_click;
    images.classList.remove("bounceInRight");
    images.classList.add("bounceInRight");
    setTimeout(function () {
      images.classList.remove("bounceInRight");
      checker = true;
    }, 1000);
  });
});

$('.accordion-child').children('.title').on('click', function(event){
    $(this).next('.slide-box').slideToggle('slow');
    event.preventDefault();
});

$(".click-delete").on("click",function(e){
    if(confirm("Do you want to delete?")) {
        $(this).parents("tr").remove();
    }
    e.preventDefault();

});

$(function () {
    StickButtonBlock();
    $( document ).on( 'scroll', StickButtonBlock);

    function StickButtonBlock() {
        var isSticky = $( '#footer' )[0].getBoundingClientRect().top - window.innerHeight;
        if ( isSticky <= 0 ) {
            $( '.btn-box6' ).addClass( 'sticky' );
        } else {
            $( '.btn-box6' ).removeClass( 'sticky' );
        }
    }
});
